import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, CircularProgress, Button, Snackbar, IconButton } from "@material-ui/core";
import ExamTabMaker from "../Exam/ExamTabMaker";
import TableMaker from "./TableMaker";
import { makeStyles } from "@material-ui/core/styles";
import { makeReqBody, url } from "./store.js";
import moment from "moment";
import StudentDetailSmartQBT from "./StudentDetailSmartQBT";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import MuiAlert from "@material-ui/lab/Alert";
import { useTheme } from "@material-ui/styles";
import { UserContext } from "../../Context";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
}));




function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const history = useHistory();
  const [firstIndexData, setFirstIndexData] = useState({});
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const theme = useTheme();
  const [reRender, setRerender] = useState(false);
  const { user } = useContext(UserContext)

  const [headers, setHeaders] = useState([])


  useEffect(() => {

    console.log("exportData", user)
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? "SCHOOL_SMART_QBT_STUDENT_EXAM_DETAILS" : "SCHOOL_GET_EXAM_FOR_STUDENT",
              busiParams: {
                examTermId: props.examTermId,
                studentInstanceId: window.localStorage.studentInstanceId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setFirstIndexData(data.respData[0].respMsg.result[0])
          let row = [];
          let filteredData = [];
          if (window.localStorage.boardId > 6 && window.localStorage.boardId < 10) {
            filteredData = data.respData[0].respMsg.result.filter(
              (item) => item.smartStatus.toUpperCase() === "PUBLISHED"
            );
          } else {
            filteredData = data.respData[0].respMsg.result;
          }
          filteredData.length > 0
            ? filteredData.map((item, index) => {
              window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ?
                item.reset === "Yes" ?
                  row.push([
                    {
                      title: item.smartQbtExamName,
                      type: "text"
                    },
                    {
                      title: props.dateFromatFunc(
                        item.startDateTime,
                        item.endDateTime
                      ),
                      startDate: item.startDateTime,
                      endDate: item.endDateTime,
                      type: "schedule",
                      mytime: item.startDateTime,
                    },
                    {
                      title: "No file",
                      type: "text",
                    },
                    { title: `${item.obtainedMarks} / ${item.totalMarks}`, type: "text" },
                    {
                      title: "No file",
                      type: "text",
                    },
                    {
                      fullDetails: item,
                      typeFeature: "smartExamAnalysis"
                    },
                    {
                      title: "Exam",
                      type: window.localStorage.roleId == 5 ? "text" : "systemExam",
                      timestart: item.startDateTime,
                      timeend: item.endDateTime,
                      examStatus: item.examStatus,
                      fullDetails: item,
                    },

                  ])
                  : row.push([
                    {
                      title: item.smartQbtExamName,
                      type: "text"
                    },
                    {
                      title: props.dateFromatFunc(
                        item.startDateTime,
                        item.endDateTime
                      ),
                      mytime: item.startDateTime,
                      startDate: item.startDateTime,
                      endDate: item.endDateTime,
                      type: "schedule",
                    },
                    {
                      title:
                        item.attachment === null ||
                        item.attachment === undefined ||
                        item.attachment.trim() === "" ||
                        item.fileName,
                      type:
                        item.attachment === null ||
                        item.attachment === undefined ||
                        item.attachment.trim() === "" ||
                        item.fileType,
                      href: item.attachment,
                    },
                    { title: `${item.obtainedMarks} / ${item.totalMarks}`, type: "text" },
                    {
                      title:
                        item.uploadAnswerDetail === null ||
                          item.uploadAnswerDetail === undefined ||
                          item.uploadAnswerDetail.trim() === ""
                          ? ""
                          : item.uploadAnswerFileName,
                      type:
                        item.uploadAnswerDetail === null ||
                          item.uploadAnswerDetail === undefined ||
                          item.uploadAnswerDetail.trim() === ""
                          ? "text"
                          : item.uploadAnswerFileType,
                      href: item.uploadAnswerDetail,
                    },
                    {
                      fullDetails: item,
                      typeFeature: "smartExamAnalysis"
                    },
                    {
                      type: window.localStorage.roleId == 5 ? "text" : "systemExam",
                      timestart: item.startDateTime,
                      timeend: item.endDateTime,
                      examStatus: item.examStatus,
                      fullDetails: item,
                    },
                  ])
                : row.push([
                  { title: item.subjectName, type: "text" },
                  { title: item.examTypeName, type: "text" },
                  {
                    startDate: item.startDateTime,
                    endDate: item.endDateTime,
                    title: item.examType !== 2 ? props.dateFromatFunc(
                      item.startDateTime,
                      item.endDateTime
                    ) : item.examType === 2 ? moment(item.examDate)
                      .format("MMM DD, YYYY, HH:mm")
                      .toString() : '',
                    mytime: item.examType === 1 ? item.startDateTime : item.examType === 2 ? item.examDate : '',
                    type: item.examType !== 2 ? "schedule" : "text",
                  },

                  {
                    title:
                      item.attachment === null ||
                        item.attachment === undefined ||
                        item.attachment.trim() === "" ||
                        item.examType === 2 && Date.parse(item.examDate) >= Date.parse(new Date())
                        ? ""
                        : item.fileName,
                    type:
                      item.attachment === null ||
                        item.attachment === undefined ||
                        item.attachment.trim() === "" ||
                        item.examType === 2 && Date.parse(item.examDate) >= Date.parse(new Date())
                        ? "text"
                        : item.fileType,
                    href: item.attachment,
                  },
                  { title: item.comments, type: "text" },
                  {
                    title: item.uploadMarksfileName,
                    type: item.uploadMarksType,
                    href: item.uploadMarks,
                  },
                  { title: item.publishMarks === 1 ? `${item.obtainedMarks} / ${item.totalMarks}` : '', type: "text" },
                  {
                    title:
                      item.uploadAnswerDetail === null ||
                        item.uploadAnswerDetail === undefined ||
                        item.uploadAnswerDetail.trim() === ""
                        ? ""
                        : item.uploadAnswerFileName,
                    type:
                      item.uploadAnswerDetail === null ||
                        item.uploadAnswerDetail === undefined ||
                        item.uploadAnswerDetail.trim() === ""
                        ? "text"
                        : item.uploadAnswerFileType,
                    href: item.uploadAnswerDetail,
                  },
                ])
            })
            : row.push([
              { title: "No Data Available", type: "text" },
              {
                title: " ",
                type: "text",
              },
              { title: " ", type: " " },
              { title: " ", type: " " },
              { title: " ", type: " " },
              { title: " ", type: " " },
              { title: " ", type: " " },
            ]);

          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }

    setHeaders(window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? [
      "Exam Name",
      "Schedule",
      "Question Papers",
      "Marks",
      "Answer Key",
      "Marks Details",
      "Action"
    ] : [
      "Subject",
      "Exam Type",
      "Schedule",
      "Question Papers",
      "Comments",
      "Marks Details",
      "Marks",
      "Answer Key",
    ])

  }, [reRender]);


  const SmartExamAnalysisDetail = (data) => {
    setStudentData(data);
    setShowDetailAnalysis(true);
  }

  const getDeviceId = () => {
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    console.log(uid);
  };

  const handleStartExam = (data) => {
    window.localStorage.setItem("examData", JSON.stringify(data))
    // -------------------------
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    console.log(uid);

    fetch(url, makeReqBody("SCHOOL_SMART_QBT_ADD_EXAM_USER", {
      examId: data.examId,
      userId: window.localStorage.userId,
      deviceType: "web",
      deviceUUID: uid
    })).then(resp => resp.json())
      .then(dataRes => {
        console.log("dataRes", dataRes)
        if (dataRes.respData[0].respMsg.status === "Success") {
          history.push("/UploadSection/ExamWorks/Instructions", { examData: data });
        } else {
          setMessage(dataRes.respData[0].respMsg.message);
          setStatus("error");
          setSnack(true)
        }
      }).catch(error => console.error(error))

  };


  const handleResetExambyStudent = () => {
    setLoader(true);
    console.log("termData", firstIndexData);
    fetch(url, makeReqBody("SCHOOL_SMART_QBT_RESET_EXAM", {
      examId: firstIndexData.examId,
      examTermId: firstIndexData.examTermId,
      studentInstanceId: firstIndexData.studentInstanceID,
      sectionId: firstIndexData.sectionId,
      loginUserId: window.localStorage.userId
    })).then(res => res.json())
      .then(data => {
        setLoader(false);
        setRerender(!reRender)
        if (data.respData[0].message === "success") {
          setStatus("success");
          setMessage(data.respData[0].respMsg.message);
          setSnack(true);
        }
        console.log(data)
      }).catch(error => {
        console.error(error)
        setTimeout(() => {
          setLoader(false);
        }, 1500);
      })
  };


  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          {(props.firstIndex === 0 && window.localStorage.variantId === "7" && window.localStorage.roleId === "4") &&
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResetExambyStudent}
              style={{
                marginBottom: "0.5em",
                marginLeft: "1em",
              }}
              startIcon={<RotateLeftIcon />}
            >
              Reset Exam
            </Button>
          }
          {showDetailAnalysis &&
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowDetailAnalysis(false)}
              style={{
                color: "white",
                marginBottom: "0.5em",
                marginLeft: "1em",
              }}
            >
              Back
            </Button>
          }

          {!showDetailAnalysis &&
            <TableMaker
              columns={headers}
              rows={rows}
              toReload={props.toReload}
              setToReLoad={props.setToReLoad}
              SmartExamAnalysisDetail={SmartExamAnalysisDetail}
              handleStartExam={handleStartExam}
            />
          }

          {showDetailAnalysis &&
            <>
              <StudentDetailSmartQBT
                examTermId={props.examTermId}
                examId={studentData && studentData.examId}
                studentInstanceID={studentData && studentData.studentInstanceID}
                render={showDetailAnalysis === true}
              />
            </>
          }
        </>

      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={9000}
        onClose={() => setSnack(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnack(false)}
          >
            <CloseIcon
              style={{ color: theme.palette.common.tabFont }}
              fontSize="small"
            />
          </IconButton>
        }
      >
        <Alert onClose={() => setSnack(false)} severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default function EExam() {
  const classes = useStyle();
  const [rowsForTab, setRowsForTab] = React.useState([]);
  // const preMidTerm = <TableMaker columns={headers} rows={rowsForTab} />;

  // const myTableData = [preMidTerm];
  // const [termId, setTermId] = useState(1);
  const [tabheadings, setTabHeadings] = React.useState([]);
  const [eExam, setEExam] = React.useState([]);
  const [loader, setLoader] = useState(true);

  const month = {
    "01": "JAN",
    "02": "FEB",
    "03": "MAR",
    "04": "APR",
    "05": "MAY",
    "06": "JUN",
    "07": "JUL",
    "08": "AUG",
    "09": "SEP",
    "10": "OCT",
    "11": "NOV",
    "12": "DEC",
  };

  const dateFromatFunc = (startDateTime, endDateTime) => {
    const sd = startDateTime.split(" ")[0];
    const st = startDateTime.split(" ")[1];
    const et = endDateTime.split(" ")[1];

    const sdSplit = sd.split("-");
    const sy = sdSplit[0];
    const sm = month[sdSplit[1]];
    const sday = sdSplit[2];

    const stSplit = st.split(":");
    const sh = stSplit[0];
    const smin = stSplit[1];

    let startAmPm = "am";
    if (parseInt(sh) >= 12) {
      startAmPm = "pm";
    }

    const etSplit = et.split(":");
    const eh = etSplit[0];
    const emin = etSplit[1];

    let endAmPm = "am";
    if (parseInt(sh) >= 12) {
      endAmPm = "pm";
    }

    let duration =
      sm +
      " " +
      sday +
      "," +
      " " +
      sy +
      "," +
      " " +
      sh +
      ":" +
      smin +
      " " +
      startAmPm +
      " " +
      "to" +
      " " +
      eh +
      ":" +
      emin +
      " " +
      endAmPm;

    return duration;
  };

  useEffect(() => {
    setLoader(true);
    const getData = async () => {
      let termData = [];
      let subjectName = [];
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_EXAM_TERMS_FOR_BRANCH",
              busiParams: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? {
                id: window.localStorage.branchId,
                sectionId: window.localStorage.sectionId,
              } : {
                id: window.localStorage.branchId,
                standard:window.localStorage.className,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.map((item, index) => {
            subjectName.push(item.name);
            termData.push(item);
          });

          setTabHeadings(subjectName);
        })
        .catch((data) => {
          console.log("sorry something went wrong");
        });
      try {
        let AllLists = [];
        console.log("termData is = " + termData);
        termData.map((item, index) => {
          let temList = (
            <MyDataMaker
              // headers={headers}
              key={index}
              firstIndex={index}
              examTermId={item.examTermId}
              dateFromatFunc={dateFromatFunc}
            />
          );

          AllLists.push(temList);
        });

        setEExam(AllLists);
      } catch (error) {
        console.error(error);
      }
    };
    if (window.localStorage.className !== undefined) {
      getData();
    }

    setInterval(() => {
      setLoader(false);
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <ExamTabMaker tabs={tabheadings} data={eExam} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
