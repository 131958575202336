import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  AppBar,
  Toolbar,
  Switch, Tooltip, Accordion, AccordionSummary, AccordionDetails,

} from "@material-ui/core";
import moment from "moment";
import ExamTabMaker from "../Exam/ExamTabMaker";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import QuestionMappingExam from "./QuestionMappingExam";

import { url, makeReqBody } from "./store.js";
import DisplayPdf from "./DisplayPdf";
import PublishRegularExam from "../Exam/PublishRegularExam";
import ReScheduleRegularExam from "../Exam/ReScheduleRegularExam";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useHistory } from "react-router-dom";
import { uniqBy } from "lodash";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },
  myDropZone: {
    color: "black !important",
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
    "& .MuiTypography-h6": {
      color: "black !important",
    },

  },


  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? "#616161"
        : "transparent",
    minwidth: "30em",
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.white,
    width: "100%",
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },
  myDropZone: {
    color: "black !important",
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
    "& .MuiTypography-h6": {
      color: "black !important",
    },
    "& .MuiTypography-h5": {
      color: "black !important",
    },

  },

  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },
  pdfScroll: {
    overflowY: "auto",

    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',
    }
  },
}));
const tabheadings = [
  "CW",
  "HW",
  "ASSIGNMENT",
  "PROJECT",
  "OBSERVATION",
  "RECORD",
];

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  dropZone: {
    color: "black !important",
  },
  iconButton: {
    padding: '6px',
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MyDataMaker = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [answerDropzone, setAnswerDropzone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [eExamId, setEexamId] = useState("");
  const [answerKey, setAnswerKey] = useState({
    url: "",
    fileName: "",
    fileType: "",
  });
  const [open, setOpen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);
  const [uploadAnswerKey, setUploadAnswerKey] = useState(false);
  const [reRender, setRerender] = useState(false);
  const [examListType, setExamListType] = useState("");
  const [examMapping, setExamMapping] = useState(false);
  const [classTeacherExamList, setClassTeacherExamList] = useState([]);
  const [reUpload, setReUpload] = useState(false);
  const [reScheduleExamData, setReScheduleExamData] = useState(null);
  const [openPublishDialog, setOpenPublishDialog] = useState(false)
  const [publishRegularData, setPublishRegularData] = useState(null);
  const [examPublished, setExamPublished] = useState(false);
  const [selectedEpId, setSelectedEpId] = useState("");
  const [passData, setPassData] = useState({});
  const history = useHistory();




  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_EXAM_FOR_TEACHER",
              busiParams: {
                examTermId: props.examTermId,

                secSubMappingId:
                  window.localStorage.teacherType == "Class"
                    ? 0
                    : window.localStorage.secSylMappingId,
                sectionId: window.localStorage.sectionId,
                examType: props.examType,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let row = [];
          setExamListType(data.respData[0].respMsg.result[0].examType);
          if (window.localStorage.teacherType == "Class") {
            let classExam = [];
            data.respData[0].respMsg.result.map((item) => {
              classExam.push(item);
            });
            setClassTeacherExamList(classExam);
          }
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
              row.push([
                {
                  title: item.subjectName,
                  examTypeName: item.examTypeName,
                  type: item.examTypeName === "Smart Qbt" ? "radio" : "text",
                  epId: item.epId,
                  examId: item.eExamId,
                  examTermId: item.examTermId,
                  subjectId: item.subjectId,
                  actionOfExam: item.actionOfExam,
                  smartStatus: window.localStorage.boardId <= 6 && window.localStorage.teacherType === "Class" ? "PUBLISHED" : item.smartStatus
                },
                { title: item.examTypeName, type: "text" },
                {
                  title: item.examType !== 2 ? props.dateFromatFunc(
                    item.startDateTime,
                    item.endDateTime
                  ) : item.examType === 2 ? moment(item.examDate)
                    .format("MMM DD, YYYY, HH:mm")
                    .toString() : '',
                  mytime: item.examType !== 2 ? item.startDateTime : item.examType === 2 ? item.examDate : '',
                  type: "text",
                },
                {
                  title: item.examType !== 2 ? moment(item.cutOffDateTime)
                    .format("MMM DD, YYYY, HH:mm")
                    .toString() : '',
                  type: "text",
                },
                {
                  title: item.totalMarks,
                  type: "text",
                },
                {
                  title: item.passingMarks,
                  type: "text",
                },
                {
                  title: item.fileName,
                  type: item.fileType,
                  href: item.attachment, 
                },
                {
                  title: "",
                  type: "verifyExam",
                  fullDetails: item,
                },
              ]);

            })
            : row.push([
              { title: "No Data Available", type: "text" },
              {
                title: " ",
                type: "text",
              },
              { title: " ", type: " " },
              {
                title: " ",
                type: "text",
              },
              { title: " ", type: " " },
              {
                title: " ",
                type: "text",
              },
              { title: " ", type: " " },
            ]);

          if (data.respData[0].respMsg.result === null) {
            setUploadAnswerKey("false");
          } else {
            setUploadAnswerKey("true");
          }
          if (data.respData[0].respMsg.result[0].quesMappingPresent === true) {
            setExamMapping(true);
          } else {
            setExamMapping(false);
          }


          setEexamId(data.respData[0].respMsg.result[0].eExamId);
          setAnswerKey({
            url: data.respData[0].respMsg.result[0].uploadAnswerDetail,
            fileName: data.respData[0].respMsg.result[0].uploadAnswerFileName,
            fileType: data.respData[0].respMsg.result[0].uploadAnswerFileType,
          });
          setExamPublished(data.respData[0].respMsg.result[0].resultPublished);


          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [reRender]);


  const handleGetEpIdData = (e, data) => {
    setPassData(data);
    setSelectedEpId(e.target.value)
    window.localStorage.setItem("passData", JSON.stringify(data));
  };


  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSaveExamKey = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPLOAD_EXAM_ANSWERS",
              busiParams: {
                eExamId: eExamId,
                loginUserId: window.localStorage.userId,
                url: newattachment,
                fileName: myFileName,
                fileType: myFileType,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setRerender(!reRender);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }


    setAnswerDropzone(false);
  }

  const handlePdfOpen = (e, href) => {
    console.log(href);
    setOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlepdfClose = (event) => {
    setOpen(false);
    window.localStorage.removeItem("idleDisable");
  };
  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleImageOpen = (e, href) => {
    setImageOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const openDropzone = () => {
    if (rows.length <= 0) {
      setMessage("Please upload an exam first.");
      setStatus("error")
      setSnack(true);
    } else {
      setAnswerDropzone(true)
    }
  }

  const headers =
    [
      "Subject",
      "Exam Type",
      "Schedule",
      "Cut-Off Time",
      "Max Marks",
      "Passing Marks",
      "Question Paper",
      "Action",
    ];

  const reScheduleExam = (data) => {
    setReUpload(true);
    setReScheduleExamData(data);
  }

  const closeReScheduleExam = () => {
    setReUpload(false);
  }

  const successReScheduleExam = () => {
    setRerender(!reRender);
    setReUpload(false);
  }

  const publishRegularExam = (data) => {
    setPublishRegularData(data);
    setOpenPublishDialog(true);
  }

  const closePublishExamRegular = () => {
    setOpenPublishDialog(false);
  }
  const closePublishExamRegularSuccess = () => {
    setRerender(!reRender);
    setOpenPublishDialog(false);
  }

  const loadList = () => {
    setRerender(!reRender);
  }

  return (
    <React.Fragment>
      {examPublished === false && window.localStorage.teacherType == "Subject" &&
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            props.handleOpenUploadDialog(e, props.examTermId);
          }}
          style={{ color: "white", marginBottom: "0.5em" }}
        >
          Upload Exam
        </Button>}
      {rows.length > 0 &&
        <>
          <QuestionMappingExam
            examId={eExamId}
            examTermId={props.examTermId}
            examType={examListType}
            examMapping={examMapping}
            classTeacherSubject={classTeacherExamList}
            loadList={loadList}
            selectedEpId={selectedEpId}
          />
        </>
      }
      {window.localStorage.teacherType && window.localStorage.teacherType === "Subject" && rows.length > 0 && examMapping === true && examListType !== 3 &&
        <>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white", marginLeft: "1em", marginTop: "-0.5em" }}
            onClick={openDropzone}
          >
            Upload Answer
          </Button>
          <span
            onClick={
              answerKey.fileType === "pdf"
                ? (e) => handlePdfOpen(e, answerKey.url)
                : answerKey.fileType === "png" ||
                  answerKey.fileType === "jpg" ||
                  answerKey.fileType === "jpeg"
                  ? (e) => handleImageOpen(e, answerKey.url)
                  : null
            }
            style={{
              maxWidth: "10em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: theme.palette.common.blue,
              marginLeft: "1em",
              cursor: "pointer",
            }}>{answerKey.fileName}</span>
        </>
      }
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <TableMaker
          columns={headers}
          rows={rows}
          toReload={props.toReload}
          setToReLoad={props.setToReLoad}
          reScheduleExam={reScheduleExam}
          publishRegularExam={publishRegularExam}
          selectedEpId={selectedEpId}
          handleGetEpIdData={handleGetEpIdData}
        />
      )}

      {/* Answer Key */}
      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        filesLimit={1}
        maxFileSize={6000000}
        open={answerDropzone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={() => setAnswerDropzone(false)}
        onSave={(files) => handleSaveExamKey(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        acceptedFiles={["image/*", "application/pdf"]}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Dialog
        fullScreen={displayFullscreen}
        open={open}
        onClose={handlepdfClose}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}></Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
            <Tooltip
              title="Download Pdf"
              classes={{
                tooltip: classes.customTooltip,
              }}>
              <IconButton className={classes.iconButton} onClick={() => window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item>
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={imageOpen}
        onClose={handleImageClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleImageClose}
        ></DialogTitle>
        <DialogContent>
          <img
            src={hrefData}
            alt="viewer"
            style={{ height: "40em", width: "40em" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleImageClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <ReScheduleRegularExam
        openReScheduleExam={reUpload}
        closeReScheduleExam={closeReScheduleExam}
        examId={reScheduleExamData && reScheduleExamData.eExamId}
        subjectName={reScheduleExamData && reScheduleExamData.subjectName}
        successReScheduleExam={successReScheduleExam}
        examType={reScheduleExamData && reScheduleExamData.examType}
        totalTime={reScheduleExamData && reScheduleExamData.totalTime}
      />


      <PublishRegularExam
        publishExamRegular={openPublishDialog}
        closePublishExamRegular={closePublishExamRegular}
        examId={publishRegularData && publishRegularData.eExamId}
        closePublishExamRegularSuccess={closePublishExamRegularSuccess}
      />
    </React.Fragment>
  );
};

export default function EExamTeacher(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [isReady, setIsReady] = useState(false);
  const [myTableData, setMyTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [chapterSelected, setChapterSelected] = useState({});
  const [materialAdded, setMaterialAdded] = useState(false);
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [smartQbtExamName, setSmartQbtExamName] = useState("")

  const [resourceId, setResourceId] = useState(0);
  const [eExam, setEExam] = useState([]);
  const [tabHeadings, setTabHeadings] = useState([]);
  const [cutOff, setCutOff] = useState(0);
  const [marks, setMarks] = useState({
    passingMarks: 0,
    maxMarks: 0,
  });
  const [subjectSelected, setSubjectSelected] = useState({
    className: "",
    noOfPeriod: 0,
    secSubSylMappingId: 0,
    sectionId: 0,
    sectionNo: "",
    subjectId: 0,
    subjectName: "",
  });

  const [examTypes, setExamTypes] = useState([]);
  const [examModeType, setExamModeType] = useState("");
  const [examDate, setExamDate] = useState(null);

  const [subjects, setSubjects] = useState(
    JSON.parse(window.localStorage.users || "[]")
  );
  const [myLoader, setMyLoader] = useState(false);
  const [examPatternList, setExamPatternList] = useState([]);
  const [examPattern, setExamPattern] = useState(null);
  const [viewExamPatternDailog, setViewExamPatternDailog] = useState(false)
  const [viewPatternData, setViewPatternData] = useState([])
  const [expanded, setExpanded] = useState(0);

  const month = {
    "01": "JAN",
    "02": "FEB",
    "03": "MAR",
    "04": "APR",
    "05": "MAY",
    "06": "JUN",
    "07": "JUL",
    "08": "AUG",
    "09": "SEP",
    10: "OCT",
    11: "NOV",
    12: "DEC",
  };

  const dateFromatFunc = (startDateTime, endDateTime) => {
    console.log("inside date func");

    const sd = startDateTime.split(" ")[0];
    const st = startDateTime.split(" ")[1];
    const et = endDateTime.split(" ")[1];

    const sdSplit = sd.split("-");
    const sy = sdSplit[0];
    const sm = month[sdSplit[1]];
    const sday = sdSplit[2];

    const stSplit = st.split(":");
    const sh = stSplit[0];
    const smin = stSplit[1];

    let startAmPm = "am";
    if (parseInt(sh) >= 12) {
      startAmPm = "pm";
    }

    const etSplit = et.split(":");
    const eh = etSplit[0];
    const emin = etSplit[1];

    let endAmPm = "am";
    if (parseInt(sh) >= 12) {
      endAmPm = "pm";
    }

    let duration =
      sm +
      " " +
      sday +
      "," +
      " " +
      sy +
      "," +
      " " +
      sh +
      ":" +
      smin +
      " " +
      startAmPm +
      " " +
      "to" +
      " " +
      eh +
      ":" +
      emin +
      " " +
      endAmPm;

    return duration;
  };



  useEffect(() => {
    setLoader(true);
    const getData = async () => {
      let termData = [];
      let subjectName = [];
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_EXAM_TERMS_FOR_BRANCH",
              busiParams: {
                id: window.localStorage.branchId,
                standard: window.localStorage.className,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.map((item, index1) => {
            subjectName.push(item.name);
            termData.push(item);
          });

          setTabHeadings(subjectName);
        })
        .catch((data) => {
          console.log("sorry something went wrong");
        });
      try {
        let AllLists = [];

        termData.map((item, index) => {
          let temList = (
            <MyDataMaker
              key={index}
              // headers={headers}
              handleOpenUploadDialog={handleOpenUploadDialog}
              examTermId={item.examTermId}
              materialAdded={materialAdded}
              dateFromatFunc={dateFromatFunc}
            />
          );

          AllLists.push(temList);
        });
        console.log(AllLists);
        setEExam(AllLists);
      } catch (error) {
        console.error(error);
      }
    };

    if (window.localStorage.className !== undefined) {
      getData();
    }

    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [materialAdded]);

  const handleOpenUploadDialog = async (e, id) => {
    setResourceId(id);
    setUploadDialog(true);
    setFromDate(null);
    setToDate(null);
    setCutOff(0);
    setExamModeType("");
    setSubjectSelected({
      className: "",
      noOfPeriod: 0,
      secSubSylMappingId: 0,
      sectionId: 0,
      sectionNo: "",
      subjectId: 0,
      subjectName: "",
    });
    setExamPattern(null)
    setMarks({
      maxMarks: "",
      passingMarks: "",
    })
    setMyAttachment({
      attachment: "",
      fileName: "",
      fileType: "",
    })
    setMaterialName("")
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  useEffect(() => {
    try {
      fetch(
        url,
        makeReqBody("MDM_GET_LOOKUP_SUB_CATG", {
          categoryName: "ExamType",
          flag: true,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Exam Type:", data.respData[0].respMsg.results);
          setExamTypes(data.respData[0].respMsg.results);
          console.log(examTypes);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (examModeType === 3 && subjectSelected.subjectId != 0) {
      setMyLoader(true);
      try {
        fetch(url, makeReqBody("SCHOOL_EXAM_PATTERN_BY_SECTIONID_AND_SUBJECTID", {
          sectionId: window.localStorage.sectionId,
          subjectId: subjectSelected.subjectId,
          status: true
        })).then((response) => response.json())
          .then(data => {       
            const uniqueData = uniqBy(data.respData[0].respMsg.result, 'epId');
            setExamPatternList(uniqueData)
            console.log("pattern", data)
          })
      } catch (error) {
        console.error(error);
        setMyLoader(false);
      }
    }

  }, [subjectSelected]);



  const handleUploadSubmit = () => {
    console.log("hello")
    if (examModeType === 1 && Date.parse(fromDate) < Date.parse(new Date()) || examModeType === 3 && Date.parse(examDate) < Date.parse(new Date())) {
      setMessage("Exam time cannot be less than system time.");
      setStatus("error");
      setSnack(true);
      return false;
    } else if (examModeType === 1 && parseInt(cutOff) === 0) {
      setMessage("Please Enter Cut of Duration(Mins).");
      setStatus("error")
      setSnack(true);
      return false;
    } else if (examModeType === 3 && smartQbtExamName === "") {
      setMessage("Please Enter the Exam Name.");
      setStatus("error");
      setSnack(true);
      return;
    }
    console.log(resourceId);
    setMyLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_EXAM",
              busiParams: {
                attachment: examModeType !== 3 ? myAttachment.attachment : null,
                branchId: parseInt(window.localStorage.branchId),
                cutOffDuration: examModeType === 1 ? parseInt(cutOff) : 2,
                description: "string",
                employeeID: parseInt(window.localStorage.employeeId),
                endDateTime: examModeType !== 2 ? moment(toDate).format("YYYY-MM-DD HH:mm:ss") : '',
                examTermId: resourceId,
                fileName: examModeType !== 3 ? myAttachment.fileName : "",
                fileType: examModeType !== 3 ? myAttachment.fileType : "",
                loginUserId: parseInt(window.localStorage.userId),
                passingMarks: parseInt(marks.passingMarks),
                secSubMappingId: subjectSelected.secSubSylMappingId,
                sectionId: parseInt(window.localStorage.sectionId),
                startDateTime: examModeType !== 2 ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss") : '',
                subjectId: subjectSelected.subjectId,
                subjectName: subjectSelected.subjectName,
                target: "internal",
                totalMarks: parseInt(marks.maxMarks),
                epId: examModeType === 3 ? examPattern.epId : 0,
                examPatternSrc: examModeType === 3 ? examPattern.source : 0,
                totalTime: examModeType === 3 ? examPattern.totalTime : 0,
                url: "",
                examType: examModeType,
                examDate: examModeType === 2 ? moment(examDate).format("YYYY-MM-DD HH:mm:ss") :  moment(fromDate).format("YYYY-MM-DD HH:mm:ss"),
                examName: smartQbtExamName,

              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setMyLoader(false);
          setMaterialAdded(!materialAdded);
          setMaterialName("");
          setCutOff(0);
          setToDate();
          setFromDate();
          setMarks({
            maxMarks: "",
            passingMarks: "",
          });
          setExamModeType("");
          setExamDate(null);
          setSubjectSelected({
            className: "",
            noOfPeriod: 0,
            secSubSylMappingId: 0,
            sectionId: 0,
            sectionNo: "",
            subjectId: 0,
            subjectName: "",
          });
          setFromDate(null);
          setToDate(null);
          setUploadDialog(false);
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(false);
        });
    } catch (error) {
      console.log(error);
      setUploadDialog(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });



  useEffect(() => {
    setMyLoader(true);
    if (examPattern !== null) {
      try {
        fetch(
          url,
          makeReqBody(examPattern.source === "Branch" ? "SCHOOL_EXAM_PATTERN_GETBYID_BRANCH_EXAM_PATTERN" : "SCHOOL_EXAM_PATTERN_GET_BY_ID", {
            examPatternId: examPattern.epId
          })
        ).then((response) => response.json())
          .then((data) => {
            setViewPatternData(data.respData[0].respMsg.result);

            setMarks({
              maxMarks: data.respData[0].respMsg.result[0].totalMarks,
              passingMarks: data.respData[0].respMsg.result[0].passingMarks,
            })
            setMyLoader(true);
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    }

  }, [examPattern]);

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <ExamTabMaker tabs={tabHeadings} data={eExam} subjectBased={true} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
        className={classes.dialogContainer}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Upload Exam
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
          >
            <Grid item container spacing={1} alignItems="center">

              <Grid item sm>
                <TextField
                  id="examMode"
                  label="Exam Room*"
                  select
                  name="subjectId"
                  variant="outlined"
                  fullWidth
                  value={examModeType}
                  className={classes.customDropdown}
                  onChange={(e) => {
                    console.log("e", e.target.value)
                    setExamModeType(e.target.value);
                  }}
                >
                  {examTypes && examTypes.map((item) => (
                    <MenuItem value={item.subCategoryId}>
                      {item.subCategory}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {examModeType === 3 &&
                <Grid item sm>
                  <TextField
                    id="examName"
                    label="Exam Name*"
                    variant="outlined"
                    fullWidth
                    value={smartQbtExamName}
                    onChange={(e) => setSmartQbtExamName(e.target.value)}
                  />
                </Grid>}
              <Grid item sm>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  label="Subject Name *"
                  name="SubjectName"
                  value={subjectSelected.subjectId === 0 ? null : subjectSelected}
                  onChange={(e) => {
                    setSubjectSelected(e.target.value);
                    setExamPattern(null);
                  }}
                >
                  {subjects && subjects.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.subjectName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {examModeType === 3 &&
              <Grid item container spacing={2} alignItems="center">
                <Grid item sm={6}>
                  <TextField
                    id="Exam Patern"
                    label="Exam Patern *"
                    select
                    name="examPattern"
                    variant="outlined"
                    fullWidth
                    value={examPattern}
                    className={classes.customDropdown}
                    onChange={(e) => {
                      setExamPattern(e.target.value);
                    }}
                  >
                    {examPatternList && examPatternList.map((item) => (
                      <MenuItem value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setViewExamPatternDailog(true)}
                    disabled={examPattern === null}
                  >
                    View Pattern
                  </Button>
                </Grid>
              </Grid>
            }
            <Grid item container spacing={1} alignItems="center">
              <Grid item sm>
                <NumberFormat
                  customInput={TextField}
                  variant="outlined"
                  allowNegative={false}
                  decimalScale={0}
                  disabled={examModeType === 3}
                  fullWidth
                  label="Max Marks*"
                  inputProps={{ maxLength: 3 }}
                  value={marks.maxMarks}
                  id="maxMarks"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setMarks({ ...marks, maxMarks: e.target.value });
                    } else {
                      setStatus("Warning");
                      setMessage("Negative Value Not Allowed");
                      setSnack(true);
                    }
                  }}
                />
              </Grid>
              <Grid item sm>
                <NumberFormat
                  customInput={TextField}
                  variant="outlined"
                  allowNegative={false}
                  decimalScale={0}
                  disabled={examModeType === 3}
                  fullWidth
                  label="Passing Marks *"
                  value={marks.passingMarks}
                  inputProps={{ maxLength: 3 }}
                  id="passingMarks"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      if (
                        parseInt(e.target.value) === 0 ||
                        e.target.value == "" ||
                        parseInt(e.target.value) <= parseInt(marks.maxMarks)
                      ) {
                        setMarks({ ...marks, passingMarks: e.target.value });
                      } else {
                        setStatus("Warning");
                        setMessage(
                          "Passing Marks Cannot Be Greater Than Maximum Marks"
                        );
                        setSnack(true);
                      }
                    } else {
                      setStatus("Warning");
                      setMessage("Negative Value Not Allowed");
                      setSnack(true);
                    }
                  }}

                />
              </Grid>
            </Grid>
            {(examModeType === 1 || examModeType === 3) &&
              <Grid item container spacing={1} alignItems="center" sm>
                <Grid item sm>
                  <DateTimePicker
                    label="From *"
                    disablePast
                    inputVariant="outlined"
                    ampm={false}
                    fullWidth
                    value={fromDate}
                    onChange={(mydate) => {
                      if (moment(mydate).format("YYYY-MM-DD HH:mm") === moment(toDate).format("YYYY-MM-DD HH:mm")) {
                        setStatus("Warning");
                        setMessage(
                          "Start Time should not be equal to End time."
                        );
                        setSnack(true);
                      } else if (moment(mydate).format("YYYY-MM-DD HH:mm") > moment(toDate).format("YYYY-MM-DD HH:mm")) {
                        setStatus("Warning");
                        setMessage(
                          "Start Time should not be greater than End time."
                        );
                        setSnack(true);
                      } else {
                        setFromDate(mydate);
                        examModeType === 3 && setToDate(mydate + examPattern.totalTime * 60000);
                      }
                    }}
                  />
                </Grid>
                <Grid item sm>
                  <DateTimePicker
                    label="To *"
                    inputVariant="outlined"
                    ampm={false}
                    fullWidth
                    disablePast
                    disabled={examModeType === 3}
                    value={toDate}
                    onChange={(mydate) => {
                      if (moment(mydate).format("YYYY-MM-DD HH:mm") === moment(fromDate).format("YYYY-MM-DD HH:mm")) {
                        setStatus("Warning");
                        setMessage(
                          "Start Time should not be equal to End time."
                        );
                        setSnack(true);
                      }
                      else if (
                        moment(mydate, "YYYY-MM-DD").diff(
                          moment(fromDate, "YYYY-MM-DD")
                        ) >= 0
                      ) {
                        setToDate(mydate);
                      } else {
                        setStatus("Warning");
                        setMessage(
                          "End time should be greater than the Start Time"
                        );
                        setSnack(true);
                      }
                    }}
                  />
                </Grid>
                {examModeType === 1 &&
                  <Grid item sm>
                    <TextField
                      variant="outlined"
                      label="Cut-Off Duration(Mins)"
                      inputProps={{ type: "number", min: 0 }}
                      value={cutOff}
                      onChange={(e) => {
                        if (e.target.value >= 0 || e.target.value === "") {
                          setCutOff(e.target.value);
                        } else {
                          setStatus("Warning");
                          setMessage("Negative Value Not Allowed");
                          setSnack(true);
                        }
                      }}
                    />
                  </Grid>}
              </Grid>
            }

            {examModeType === 2 &&
              <Grid item container alignItems="center" sm>
                <Grid item container sm>
                  <DateTimePicker
                    label="Exam Date *"
                    disablePast
                    inputVariant="outlined"
                    ampm={false}
                    fullWidth
                    value={examDate}
                    onChange={setExamDate}
                  />
                </Grid>
              </Grid>
            }
            {examModeType !== 3 &&
              <Grid item container>
                <Grid item onClick={handleDropZone}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                  >
                    Upload *
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    style={{ color: "blue" }}
                  >
                    {materialName}
                  </Typography>
                </Grid>
              </Grid>
            }

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            disabled={
              examModeType !== 3 && myAttachment.attachment === "" ||
              examModeType !== 3 && myAttachment.attachment === " " ||
              (examModeType === 1 || examModeType === 3) && toDate === null ||
              (examModeType === 1 || examModeType === 3) && fromDate === null ||
              examModeType === 1 && cutOff === 0 ||
              examModeType === 2 && examDate === null ||
              parseFloat(marks.passingMarks) === 0 ||
              marks.passingMarks === "" ||
              parseFloat(marks.maxMarks) === 0 ||
              marks.maxMarks === "" ||
              subjectSelected.subjectId === 0 ||
              parseFloat(marks.passingMarks) > parseFloat(marks.maxMarks) ||
              loader === true
            }
            onClick={handleUploadSubmit}
          >
            {loader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={viewExamPatternDailog}
        onClose={() => setViewExamPatternDailog(false)}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setViewExamPatternDailog(false)}
        >
          View Eaxm Pattern
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
          >
            <Grid item container spacing={2}>
              {viewPatternData.map((elem, idx) => (
                <>
                  <Grid item sm={3}>
                    <Typography variant="subtitle1">Board Name : <strong>{elem.boardName}</strong></Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography variant="subtitle1">Exam Name:  <strong>{elem.name}</strong></Typography>
                  </Grid>
                </>
              ))}

            </Grid>
            <Grid item spacing={2}>
              {viewPatternData[0] && viewPatternData[0].examPatternSubjectWise &&
                viewPatternData[0].examPatternSubjectWise.map((item, index) => (
                  <Grid style={{ marginTop: "2em", marginBottom: '2em' }}>
                    <Accordion expanded={expanded === index} onChange={handleExpand(index)}>
                      <AccordionSummary
                        style={{ backgroundColor: "#f2f2f2" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel1a-content-${index}`}
                        id={`panel1a-header${index}`}
                      >
                        <Typography variant="h4"
                          style={{
                            color: "#000",
                            fontWeight: "700",
                            fontSize: "1.2em",
                            textTransform: "uppercase"
                          }}
                        >{item.subjectName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid direction="column" item container spacing={2} >
                          {item.identifierDetails.map((el, idx) => (
                            <Grid item container spacing={1} key={idx} alignItems="center" justify="space-between">
                              <Grid item sm>
                                <Typography variant="subtitle1">Identifier type: <strong>{el.identifierName}</strong></Typography>
                              </Grid>
                              <Grid item sm>
                                <Typography variant="subtitle1">No Question: <strong>{el.no_Of_question}</strong></Typography>
                              </Grid>

                              <Grid item sm>
                                <Typography variant="subtitle1">No of mandatory Question: <strong>{el.no_of_mandatoryQuestion}</strong></Typography>
                              </Grid>
                              <Grid item sm>
                                <Typography variant="subtitle1">Total Marks:  <strong>{el.toMarks}</strong></Typography>
                              </Grid>

                              <Grid item sm>
                                <Typography variant="subtitle1">Passing marks: <strong>{el.passingMarks}</strong></Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={() => setViewExamPatternDailog(false)}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={dropZone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={handleDropZoneClose}
        dropzoneParagraphClass={classes.dropZone}
        acceptedFiles={["image/*", "application/pdf"]}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={9000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
