import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";

import { Grid, Typography, Fade, } from "@material-ui/core";

import { useRouteMatch } from "react-router-dom";
import { url, makeReqBody } from "./Ui/store.js";

import EExam from "./Ui/eExam.js";
import ExamAnalysis from "./Ui/ExamAnalysis.js";
import ExamAnalysisTeacher from "./Ui/ExamAnalysisTeacher.js";
import EExamTeacher from "./Ui/EExamTeacher.js";
import SmartExam from "./Ui/SmartExam";
import RegularExamTeacher from "./Ui/RegularExamTeacher";
import DetailedAnalysis from "./Ui/DetailedAnalysis";
import SmartQBT from './Ui/SmartQBT';
import ExamAnalysisTeacherSmartQBT from "./Ui/ExamAnalysisTeacherSmartQBT.js";
import RectificationWork from "./Ui/RectificationWork.js";
import RectificationWorkTeacher from "./Ui/RectificationWorkTeacher.js";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
  subContainer: {
    ...theme.typography.subContainer,
  },
  customDropdown: {
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(14px, 14px) scale(1)',
      pointerEvents: 'none',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 14px',
    },
  },
}));

export default function ExamMode(props) {
  const classes = useStyle();
  let { path, urls } = useRouteMatch();

  const eexam = (
    <React.Fragment>
      {console.log(window.location.pathname)}
      <Fade
        in={window.location.pathname === "/ExamMode/eExam"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="Exam Room" moduleId={21} showInstruction={true} />
          <EExam />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const eexamteacher = (
    <React.Fragment>
      {console.log(window.location.pathname)}
      <Fade
        in={window.location.pathname === "/ExamMode/eExam"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="Exam Room" moduleId={21} />
          {(window.localStorage.boardId > 6 && window.localStorage.boardId < 10) &&
            window.localStorage.roleId == 3
            ? <SmartQBT /> : <EExamTeacher />}

        </Grid>
      </Fade>
    </React.Fragment>
  );

  const examanalysis = (
    <React.Fragment>
      {console.log(window.location.pathname)}
      <Fade
        in={window.location.pathname === "/ExamMode/ExamAnalysis"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="EXAM ANALYSIS" moduleId={22} />
          <ExamAnalysis />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const examanalysisteacher = (
    <React.Fragment>
      {console.log(window.location.pathname)}
      <Fade
        in={window.location.pathname === "/ExamMode/ExamAnalysis"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="EXAM ANALYSIS" moduleId={22} />
          {window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ?
            <ExamAnalysisTeacherSmartQBT /> : <ExamAnalysisTeacher />
          }
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const rectification = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === "/ExamMode/rectification"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="Rectification Work" moduleId={41} />
          <RectificationWork />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const rectificationTeacher = (
    <React.Fragment>
      <Fade
        in={window.location.pathname === "/ExamMode/rectification"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="Rectification Work" moduleId={41} />
          <RectificationWorkTeacher />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  const detailedAnalysis = (
    <React.Fragment>
      {console.log(window.location.pathname)}
      <Fade
        in={window.location.pathname === "/ExamMode/detailed-analysis"}
        mountOnEnter
        unmountOnExit
        timeout={{ accept: 800, enter: 1000, exit: 300 }}
      >
        <Grid item container direction="column">
          <WorkSpaceHeader title="Detailed Analysis" moduleId={38} />
          <DetailedAnalysis />
        </Grid>
      </Fade>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >

        {window.location.pathname === "/ExamMode/detailed-analysis" ? (
          detailedAnalysis
        ) : window.location.pathname === "/ExamMode" ? (
          eexam
        ) : window.location.pathname === "/ExamMode/eExam" ? (
          window.localStorage.roleId == 3 ? (
            eexamteacher
          ) : (
            eexam
          )
        ) : window.location.pathname === "/ExamMode/ExamAnalysis" ? (
          window.localStorage.roleId == 3 ? (
            examanalysisteacher
          ) : (
            examanalysis
          )
        ) : window.location.pathname === "/ExamMode/rectification" ? (
          window.localStorage.roleId == 3 ? (
            rectificationTeacher
          ) : (
            rectification
          )
        ) : (
          <Typography variant="h1">To be designed</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}
